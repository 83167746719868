exports.components = {
  "component---src-pages-404-index-js": () => import("./../../../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-about-us-index-js": () => import("./../../../src/pages/about-us/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-applink-index-js": () => import("./../../../src/pages/applink/index.js" /* webpackChunkName: "component---src-pages-applink-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-documents-and-disclosures-contentful-legal-document-slug-js": () => import("./../../../src/pages/documents-and-disclosures/{ContentfulLegalDocument.slug}.js" /* webpackChunkName: "component---src-pages-documents-and-disclosures-contentful-legal-document-slug-js" */),
  "component---src-pages-documents-and-disclosures-index-js": () => import("./../../../src/pages/documents-and-disclosures/index.js" /* webpackChunkName: "component---src-pages-documents-and-disclosures-index-js" */),
  "component---src-pages-download-index-js": () => import("./../../../src/pages/download/index.js" /* webpackChunkName: "component---src-pages-download-index-js" */),
  "component---src-pages-how-it-works-index-js": () => import("./../../../src/pages/how-it-works/index.js" /* webpackChunkName: "component---src-pages-how-it-works-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-funds-index-js": () => import("./../../../src/pages/our-funds/index.js" /* webpackChunkName: "component---src-pages-our-funds-index-js" */),
  "component---src-pages-pricing-index-js": () => import("./../../../src/pages/pricing/index.js" /* webpackChunkName: "component---src-pages-pricing-index-js" */),
  "component---src-pages-privacy-policy-index-js": () => import("./../../../src/pages/privacy-policy/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-js" */),
  "component---src-pages-security-index-js": () => import("./../../../src/pages/security/index.js" /* webpackChunkName: "component---src-pages-security-index-js" */)
}

